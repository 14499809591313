















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Site, Media, isSite, getPreview } from "@/core/utils/linkPreview";

@Component
export default class LinkPreview extends Vue {
  @Prop({ default: () => "" }) link!: string;

  isSite = isSite;
  preview: Site | Media | null = null;
  get site() {
    if (!this.preview || !isSite(this.preview)) return "";
    return (this.preview as Site).siteName;
  }
  get title() {
    if (!this.preview || !isSite(this.preview)) return "";
    return (this.preview as Site).title;
  }
  get desc() {
    if (!this.preview || !isSite(this.preview)) return "";
    return (this.preview as Site).description;
  }
  get image() {
    if (!this.preview) return "";
    if (isSite(this.preview) && (this.preview as Site).images.length > 0)
      return (this.preview as Site).images[0];
    return this.preview.favicons[0] as string;
  }
  async fetch() {
    console.log("fetching link", this.link);
    try {
      this.preview = await getPreview(this.link);
    } catch (error) {
      console.log(error);
    }
  }
  open() {
    if (this.preview) window.open(this.preview.url, "_blank");
  }
  mounted() {
    this.fetch();
  }
}
