import { getLinkPreview } from "link-preview-js";
const cors = "https://cors-anywhere.herokuapp.com/";
export interface Media {
  url: string;
  mediaType: string;
  contentType: string;
  favicons: any[];
}
export interface Site {
  url: string;
  title: any;
  siteName: any;
  description: any;
  mediaType: any;
  contentType: string | undefined;
  images: string[];
  videos: {
    url: any;
    secureUrl: any;
    type: any;
    width: any;
    height: any;
  }[];
  favicons: any[];
}

export const isSite = (prev: Site | Media) => {
  return prev.contentType?.startsWith("text/html") || false;
};

export const getPreview = async (link: string) => {
  try {
    const data = (await getLinkPreview(cors + link)) as any;
    if (!data.title) return null;
    data.url = link;
    if (isSite(data)) return data as Site;
    return data as Media;
  } catch (error) {
    return null;
  }
};
